/*
 * 업무구분: 컴플라이언스 행동메뉴얼
 * 화 면 명: MSPAP700M
 * 화면 설명: 영업/활동관리 > 변액행동매뉴얼승인
 * 접근권한: 지점장 
 * 작 성 일: 2024.09
 * 작 성 자: 박상진
 */
<template>
  <ur-page-container class="msp" type="subpage" title="변액 행동매뉴얼 승인" :show-title="true" :topButton="true" action-type="search" @action-search-click="fn_Open700P" @on-header-left-click="fn_ClickBackBtn" >
    <template #frame-header-fixed>
      <!-- 날짜 선택 -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pb0 actingManualSearching">
        <div class="inline-label__wrap full">
          <div class="add-date tit-inline__wrap">
            <mo-date-picker bottom v-model="srchStrYmd" placeholder="조회시작일" class="ns-date-picker" @input="fn_SelectDateFrom"/>
            <span class="tilde"></span>
            <mo-date-picker bottom v-model="srchEndYmd" placeholder="조회종료일" class="ns-date-picker" @input="fn_SelectDateTo"/>
          </div>
        </div>
        <div class="actingManualMen">
          <div class="actingManualMenSubj">대상인원</div>
          <div class="actingManualMenSubj">승인</div>
          <div class="actingManualMenSubj noBorder">미승인</div>
          <div class="actingManualMenNum">{{summryVO.totCnt}}명</div>
          <div class="actingManualMenNum">{{summryVO.aprvCnt}}명</div>
          <div class="actingManualMenNum noBorder">{{summryVO.unAprvCnt}}명</div>
        </div>
        <div class="ns-segment actingManualSegmentWrap">
          <mo-segment-wrapper solid primary v-model="tab_index">
            <mo-segment-button value="0">전체</mo-segment-button>
            <mo-segment-button value="1">승인</mo-segment-button>
            <mo-segment-button value="2">미승인</mo-segment-button>
          </mo-segment-wrapper>
        </div>
      </ur-box-container>
      <!-- 날짜 선택 끝-->
    </template>
    <!-- Data 영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-list-area actingManualListWrap">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list pb80">
          <mo-list :list-data="comsSpcf">
            <template #list-item="{index, item}">
              <mo-list-item :class="{'on':item.data===true, 'bgcolor-ightred':item.data7===true, 'bgcolor-borderblue':item.data8===true && item.data===true}" @click.native.stop="fn_OnClickListItem(index, item)">
                <mo-checkbox v-model="item.data" :disabled="item.sign===false && item.admit===false" :class="{'hiddenItem':item.admit===true}" :ref="`chk${index}`"/>
                <div class="list-item__contents">
                  <div class="list-item__contents__title fexTy3-1 mb0">
                    <span class="name flex-1 fs17rem mr10 on-txt">{{item.mobslCnsltNm}}</span>
                    <div v-if="item.admit===true" class="admitBadge">승인완료</div>
                  </div>
                  <div class="list-item__contents__info fexTy3-1 actingManualListItem2">
                    <span>{{item.mobslCnsltEno}}</span>
                    <span class="bulletSeperate"></span>
                    <span>작성일시: <span :class="{'alert':item.sign===false}">{{item.lstWrtDtm}}</span></span>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
      </ur-box-container>
      <!-- 하단 버튼영역 -->
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="normal" shape="border" size="medium" class="ns-btn-round blue" :disabled="isSelect" @click="openConfirmPopup" >승인</mo-button>
        </div>
      </ur-box-container>
      <!-- 하단 버튼영역 //-->
    </ur-box-container>
    <!-- Data 영역 끝 -->

    <!-- Popup -->
    <mo-bottom-sheet ref="confirmPopup" :close-btn="closeBtn" class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        <div class="ns-btn-close" @click="closeConfirmPopup" name="닫기"></div>
      </template>
       
      <div class="customer-info-txt popupMessage1">
        승인 완료 시 해당 컨설턴트에 대하여 「컴플라이언스 행동매뉴얼」 자격(0147)이 부여되며, 변액보험 판매가 가능하게 됩니다.<br>
        컨설턴트에 의한 변액보험 불완전판매가 발생할 경우 승인자에게 영업관리자로서의 관리책임이 발생합니다.
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="doConfirm" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- Popup 끝 -->

    <!-- Popup -->
    <mo-bottom-sheet ref="procPopup" :close-btn="closeBtn" class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        <div class="ns-btn-close" @click="closeProcPopup" name="닫기"></div>
      </template>
       
      <div class="customer-info-txt popupMessage1">
        {{summryVO.procCnt}}/{{tab_index==2 ? summryVO.unAprvCnt : summryVO.totCnt}}건 처리되었습니다.
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="closeProcPopup" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- Popup 끝 -->

      <!-- indexes: 초성검색 수동 세팅 -->
      <!-- <mo-index-scroll v-if="!isEmptyView && !showReturnStr || isInit" class="ns-index-scroll" :style="indexStyle" indexes="Aㄱㄴㄷㄹㅁㅂㅅㅇㅈㅊㅋㅌㅍㅎ" @index-scroll="fn_ClickInitial"/>
      <mo-index-scroll v-if="!isEmptyView && showReturnStr && !isInit" class="ns-index-scroll" :style="indexStyle1" indexes="Aㄱㄴㄷㄹㅁㅂㅅㅇㅈㅊㅋㅌㅍㅎ" @index-scroll="fn_ClickInitial"/>       -->


    <!-- 토스트 -->
    <mo-snackbar ref="stackableSnackbar" stackable :duration="3000" /> 
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MSPAP700P   from '@/ui/ap/MSPAP700P'               // 승인현황 상세조건 
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import Msg from '@/systems/webkit/msg/msg'
  import moment from 'moment'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP700M",
    screenId: "MSPAP700M",
    components: {
      MSPAP700P,
    },
    props:{
      closeBtn:{
        type:Boolean,
        default:false
      },
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickBackBtn)     // backKey Event 등록
      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')                       // post 호출 전 Progress 타입 설정

      this.userInfo = this.getStore('userInfo').getters.getUserInfo                   // 사용자 정보

      /**
       * 지점장 여부를 확인한다.
       * 해당 사용자는 본인이 아닌 FC번호로 조회가능하다.
       */
      if(this.$cmUtil.isSrchOrgCombo() === 'Y') this.isMngr = true
      
      this.srchStrYmd = moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD')
      this.srchEndYmd = moment(new Date()).format('YYYY-MM-DD')
      
      this.fn_search()
    },
    mounted() {
      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog(this.$options.screenId)
      this.tab_index = '0'
    },
    beforeDestroy () {

      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickBackBtn)      // backKey Event 해제
      window.vue.getStore('progress').dispatch('FULL')                                  // post 호출 전 Progress 타입 설정

    },//beforeDestroy

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        actionSlot: '', // 고객명 검색 slot
        srchStrYmd: '',
        srchEndYmd: '',
        mokupdata: [
          {mobslCnsltNm:'이세별', mobslCnsltEno:'1234567890', lstWrtDtm:'미작성', sign:false, admit:false},
          {mobslCnsltNm:'방서교', mobslCnsltEno:'1234567890', lstWrtDtm:'2024-10-24 10:00', sign:true, admit:false},
          {mobslCnsltNm:'강역선', mobslCnsltEno:'1234567890', lstWrtDtm:'2024-10-24 10:00', sign:true, admit:false},
          {mobslCnsltNm:'삼종잠', mobslCnsltEno:'1234567890', lstWrtDtm:'2024-10-24 10:00', sign:true, admit:true},
          {mobslCnsltNm:'James Junior Williams Abcdefg', mobslCnsltEno:'1234567890', lstWrtDtm:'2024-10-24 10:00', sign:true, admit:true},
          {mobslCnsltNm:'Williams James Junior Abcdefg', mobslCnsltEno:'1234567890', lstWrtDtm:'2024-10-24 10:00', sign:true, admit:true},
          {mobslCnsltNm:'Williams James Junior Abcdefg', mobslCnsltEno:'1234567890', lstWrtDtm:'2024-10-24 10:00', sign:true, admit:true},
        ],
        tab_index: '', // tab contant 선택;
        comsSpcf: [],
        comsSpcf0: [],  //전체
        comsSpcf1: [],  //승인
        comsSpcf2: [],  //미승인
        summryVO: {
          totCnt:0,
          aprvCnt:0,
          unAprvCnt:0,
          procCnt: 0,
        },
        userInfo: {},                                                       // 조직 및 컨설턴트 정보
        loadingMsg: '정보 조회중',
        loadingSubMsg: '2~3분 정도 소요될 수 있습니다.',
        popup700: {},                                                       // MSPAP700P  상세조건 
        pOrgDataMSPAP700M: {},                                              // 최근 조회조건 데이터
        /**
         * 제어 변수 
         */ 
        isSearched: false,                                                  // 조회이력 저장 변수 
        isCleared: true,                                                    // 조회조건 초기화 여부 
        searchedItems: {},                                                  // 검색결과 데이터 
        isPrdOk: false,
        isSelect: true,
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      disabledStrDate () {
        return moment(this.srchEndYmd).format('YYYY-MM-DD')
      },
      disabledEndDate () {
        return moment(this.srchStrYmd).format('YYYY-MM-DD')
      },
      checkCount () {
        return this.comsSpcf.filter(item => item.data).length
      }
    },
    watch: {
      tab_index() {
        this.comsSpcf = this['comsSpcf' + this.tab_index]
      },
      checkCount() {
        if (this.checkCount > 0) this.isSelect = false
        else this.isSelect = true
      },
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      initValue(){
        let lv_Vm = this

        lv_Vm.comsSpcf = []
        lv_Vm.comsSpcf0 = []
        lv_Vm.comsSpcf1 = []
        lv_Vm.comsSpcf2 = []
        // lv_Vm.tab_index = '0'
        lv_Vm.comsSpcf = lv_Vm.comsSpcf0

        lv_Vm.summryVO.totCnt = 0
        lv_Vm.summryVO.aprvCnt = 0
        lv_Vm.summryVO.unAprvCnt = 0
      },
      /*******************************************************************************
      * Function명: fn_chkSrchPrd
      * 설명: 검색기간 체크
      *******************************************************************************/
      fn_chkSrchPrd () {
        this.isPrdOk = true

        let stDate = moment(new Date(this.srchStrYmd)).format('YYYYMMDD')
        let edDate = moment(new Date(this.srchEndYmd)).format('YYYYMMDD')

        let t_StndYmd = moment(new Date(this.srchEndYmd)).subtract(12, 'months').format('YYYYMMDD')
        let t_StrYmd = moment(new Date(this.srchStrYmd)).format('YYYYMMDD')
        console.log('검색기간 체크>>>', t_StndYmd , '[]', t_StrYmd)
        if (Number(t_StrYmd) < Number(t_StndYmd)) {
          this.isPrdOk = false
          this.getStore('confirm').dispatch('ADD', '조회구간은 최대 1년입니다.')
          this.isMore = false
        }

        let o_StndYmd = moment(new Date(this.srchEndYmd)).subtract(1, 'days').format('YYYYMMDD')
        let o_strYmd = moment(new Date(this.srchStrYmd)).format('YYYYMMDD')
        console.log(t_StndYmd)
        console.log(o_strYmd)
        
        if(Number(stDate)>Number(edDate)){
          this.isPrdOk = false
          this.getStore('confirm').dispatch('ADD', '조회시작일 조회종료일보다 클수 없습니다.')
          this.isMore = false
        }

        return this.isPrdOk
      },
      /*********************************************************
      * Function명: fn_search
      * 설명: 승인현황 조회
      *********************************************************/
      fn_search() {
        let lv_Vm = this

        lv_Vm.initValue()
        let trnstId = 'txTSSAP10S1'

        if (!this.fn_chkSrchPrd()) { // 검색기간 확인
          return
        }

        let cnsltNo = '0'
        if(!lv_Vm.$bizUtil.isEmpty(lv_Vm.pOrgDataMSPAP700M.cnslt)){
          cnsltNo = lv_Vm.pOrgDataMSPAP700M.cnslt.key
        }

        //지점
        let fofOrgNo = lv_Vm.userInfo.onpstFofOrgNo
        if(!lv_Vm.$bizUtil.isEmpty(lv_Vm.pOrgDataMSPAP700M.fofOrg)){
          fofOrgNo = lv_Vm.pOrgDataMSPAP700M.fofOrg.key
        }

        //사업부
        let hofOrgNo = lv_Vm.userInfo.onpstHofOrgNo
        if(!lv_Vm.$bizUtil.isEmpty(lv_Vm.pOrgDataMSPAP700M.hofOrg)){
          hofOrgNo = lv_Vm.pOrgDataMSPAP700M.hofOrg.key
        }

        //지역단
        let dofOrgNo = lv_Vm.userInfo.onpstDofNo
        if(!lv_Vm.$bizUtil.isEmpty(lv_Vm.pOrgDataMSPAP700M.dofOrg)){
          dofOrgNo = lv_Vm.pOrgDataMSPAP700M.dofOrg.key
        }

        let pParam = {
          cnsltNo: cnsltNo,
          frDt: lv_Vm.srchStrYmd.substr(0, 10).replace(/-/gi, '')+'000000',
          toDt: lv_Vm.srchEndYmd.substr(0, 10).replace(/-/gi, '')+'239999',
          hofOrgNo: hofOrgNo,
          dofOrgNo: dofOrgNo,
          fofOrgNo: fofOrgNo,
          // fofOrgNo: lv_Vm.userInfo.onpstFofOrgNo,
          initial: '',
        }

        lv_Vm.comsSpcf = []
        lv_Vm.comsSpcf0 = []
        lv_Vm.comsSpcf1 = []
        lv_Vm.comsSpcf2 = []

        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {

          if ( response.body != null ) {
            // lv_Vm.fcInfo.name = response.body.cnsltNm
            // lv_Vm.fcInfo.grade = response.body.chnlQulfNm
            // lv_Vm.fcInfo.team = response.body.onpstDofNm + ' ' + response.body.onpstTeamNm
            // lv_Vm.fcInfo.entrusting = response.body.lpmfc === 0 ? '' : response.body.lpmfc + ' 차월'

            // Server 에서 체크
            // if ( response.body.aPActulSpcfItmList === null && response.body.aPComsSpcfItmList === null && response.body.aPPymSpcfItmList === null ) {
            //   lv_Vm.getStore('toast').dispatch('ADD', '해당 월에 수수료 세부 내역이 존재하지 않습니다.')
            // }

            // 전체
            if ( response.body.fcList != null && response.body.fcList.length > 0 ) {
              response.body.fcList.forEach((item) =>{
                if(item.aprvScCd=='00'){
                  item.sign = false
                }else{
                  item.sign = true
                }

                if(lv_Vm.$bizUtil.isEmpty(item.lstWrtDtm)){
                  item.lstWrtDtm='미작성'
                }else{
                  item.lstWrtDtm= lv_Vm.fn_dateFormat(item.lstWrtDtm)
                }

                lv_Vm.comsSpcf0.push(item)

                if(item.aprvScCd==='02'){
                  item.admit = true
                  lv_Vm.comsSpcf1.push(item)
                }else{
                  item.admit = false
                  lv_Vm.comsSpcf2.push(item)
                }
              })
            }

            // lv_Vm.tab_index = '0'
            lv_Vm.comsSpcf = lv_Vm['comsSpcf'+lv_Vm.tab_index]

            lv_Vm.summryVO.totCnt = response.body.totCnt
            lv_Vm.summryVO.aprvCnt = response.body.aprvCnt
            lv_Vm.summryVO.unAprvCnt = response.body.unAprvCnt

          } else {
            lv_Vm.fn_InitData()
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
      },
      openConfirmPopup() {this.$refs.confirmPopup.open()},
      closeConfirmPopup() {this.$refs.confirmPopup.close()},
      openProcPopup() {this.$refs.procPopup.open()},
      closeProcPopup() {
        this.$refs.procPopup.close()
        
        this.fn_search();  
      },
      doConfirm() {
        let lv_Vm = this

        let sendList = this.comsSpcf.filter(item => item.data)

        let trnstId = 'txTSSAP10U2'
        
        let pParam = {
          aprvEplyNo: '0000000001',
          fofOrgNo: '00010275',
          fcList: sendList,
        }

        this.$refs.confirmPopup.close()

        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
          if ( response.body != null ) {
            lv_Vm.summryVO.procCnt = response.body.procCnt

            lv_Vm.openProcPopup();

            // lv_Vm.$refs.stackableSnackbar.show({text: response.body.procCnt+'/'+lv_Vm.summryVO.totCnt+'건이 처리되었습니다'})
            
          }
        })

      },
      fn_Open700P() {
        // this.fn_search()
        // return
        // this.$refs.stackableSnackbar.show({text:'팝업'})
        this.popup700 = this.$bottomModal.open(MSPAP700P,{
          properties: {
            pPage: this.$options.screenId,
            pIsMngr: this.isMngr,                     // 지점장 여부 
            // pIsMngr: true,
            pOrgData: this.pOrgDataMSPAP700M,         // 최근 조직 및 컨설턴트 
            pSelectedItems: this.searchedItems,       // 조회조건 
          },

          listeners: {
            searchPopup: (pData) => {
              // 모달 닫기
              this.$bottomModal.close(this.popup700)
    
              this.pOrgDataMSPAP700M = pData.rtnData.pOrgData       
              this.searchedItems     = pData.rtnData.selectedItems  
              this.isCleared         = pData.rtnData.isCleared      
              this.isSearched        = true                        
              // 수금체크명세 조회
              
              this.fn_search()

            }
          }
        })
      },
      fn_dateFormat(value){
        if (!value) return ''
        value = value.substr(0, 4) + '-' + value.substr(4, 2) + '-' + value.substr(6, 2) + ' ' + value.substr(8, 2) + ':' + value.substr(10, 2) + ':' + value.substr(12, 2)
        return value 
      },
      
      /**
       * @description 체크박스 이외의 영역 클릭했을 때, 체크 활성화 및 비활성화 한다.
       * @param       {Number} index 리스트 데이터의 index
       */
      fn_OnClickListItem(index, item) {
        if(item.sign===false && item.admit===false || item.admit===true) return 
        
        this.$refs[`chk${index}`].onClick()
      },
      /**
       * @description 체크된 값이 있는지 확인 후, 뒤로가기 실행 함수를 호출한다.
       */
      fn_ClickBackBtn(){
        debugger
        console.log('bk btn')
        if (this.checkedCount > 0) {
          this.fn_confirm()
          return 
        } 
        
        this.fn_BackToPrevious()

      },

      /**
       * @description - (현재조건값 === 초기조건값) 뒤로가기를 한다.
       *              - (현재조건값 !== 초기조건값) 조건 초기화 후 조회한다.
       */
      fn_BackToPrevious(){

        if(this.isSearched && !this.isCleared){  
          this.initValue()
          this.fn_search()
        }else{
          // 메뉴로 진입했으면 홈화면으로 이동한다.
          if (this.$route.query?._isMenuItem) {
            this.$router.push({name: 'MSPBC002M'})
            return
          }
          this.$router.go(-1)
        }

      },
      
      /**
       * @description confirm popup
       * @param {String} title 제목
       * @param {String} message confirm 내용
       */
      fn_confirm(title, message) {
        let lv_Vm = this
        let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
        let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
            properties: {
              type: 'B',
              content: backMsg,
              title_pos_btn: "예",
              title_neg_btn: "아니오"
            },
            listeners: {
              onPopupConfirm: () => {
                lv_Vm.$bottomModal.close(lv_AlertPop)
                this.fn_BackToPrevious()
              },
              onPopupClose: () => {
                lv_Vm.$bottomModal.close(lv_AlertPop)
              }
            }
          })
      },
      fn_SelectDateFrom (v) { // 시작일자를 선택했는 지 여부
        let beginDate = new Date(this.srchStrYmd)
        let endDate = new Date(this.srchEndYmd)
        if ( endDate < beginDate ) {
          this.getStore('confirm').dispatch('ADD', '조회시작일 조회종료일보다 클수 없습니다.')
          this.srchStrYmd = this.srchEndYmd
        }
        this.fn_search()
      },
      fn_SelectDateTo (v) { // 종료일자를 선택했는 지 여부
        let beginDate = new Date(this.srchStrYmd)
        let endDate = new Date(this.srchEndYmd)
        
        // 종료일자 자동선택
        if ( endDate < beginDate ) {
          this.getStore('confirm').dispatch('ADD', '조회시작일 조회종료일보다 클수 없습니다.')
          this.srchEndYmd = this.srchStrYmd
        }
        this.fn_search()
      },

    }
  };
</script>